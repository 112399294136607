<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import TaxRateForm from "./forms/tax-rate-form.vue";
import CustomTable from "@/components/tables/custom-table.vue";
import AddButton from "@/components/buttons/add-button.vue";

/**
 * Customers Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    AddButton,
    TaxRateForm,
    CustomTable
  },
  data() {
    return {
      formModalId:'tax-rate-form-modal',
      showModalId:'tax-rate-show-modal',
      pageHeader:{
        title: "Tarifas de impuestos",
        breadcrumbs: [
          {
            text: "Tarifas de impuestos",
            href: "/tax-rates",
            active: true
          }
        ],
      },
      table:{
        url:'/tax-rates',
        params: {
        },
        fields:[
          { key: "id", label: "Cod", sortable: true },
          { key: "name", label: "Nombre", sortable: true },
          { key: "country", label: "Cod de país", sortable: false },
          { key: "rate", label: "Tarifa", sortable: false },
          { key: "priority", label: "Prioridad", sortable: true },
          { key: "compound", label: "Compuesto", sortable: true },
          { key: "shipping", label: "Envío", sortable: true },
        ],
        formatted_fields:[
          'rate',
          'compound',
          'shipping'
        ]
      },
      formSelectedItemId:null,   
      showSelectedItemId:null,
      clone:false
    };
  },
  methods: {
    showEditModal(item, clone = false) {
      this.formSelectedItemId = item?.id || null;
      this.clone = clone;
      this.$bvModal.show(this.formModalId);
    },
    showViewModal(item) {
      this.showSelectedItemId = item?.id || null;
      this.$bvModal.show(this.showModalId);
    },
    onSuccess(){
      this.$refs.customerTable.refresh();
    },
    formatter(field, item){
      if(field === 'rate'){
        return item[field] + '%';
      }
      if(field === 'compound' || field === 'shipping'){
        return (item[field]) ? 'Si' : 'No';
      }
      return '';
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="pageHeader?.title" :items="pageHeader?.breadcrumbs" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <TaxRateForm :modal-id="formModalId" :id="formSelectedItemId" @onSuccess="onSuccess" :clone="clone"></TaxRateForm>
            <div class="text-right">
              <AddButton @click="showEditModal"></AddButton>
            </div>
            <CustomTable
              :url="table.url"
              :fields="table.fields"
              :formatted-fields="table.formatted_fields"
              :formatter="formatter"
              :params="table.params"
              @edit="showEditModal"
              ref="customerTable"
              :show-view="false"
              :showSoftDelete="false"
              @show="showViewModal"
              :show-modal-id="showModalId"
            >
            </CustomTable>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>